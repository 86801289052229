<template>
  <page :styleWrap='{background: "#fff"}'>
    <div class="detailpage" v-if='info'>
      <header-bar></header-bar>
      <info-panel :info='info' :index='0'></info-panel>
    </div>
  </page>
</template>

<script>
import infoPanel from '@/components/infoPanel/index.vue'
import postApi from '@/api/post'

export default {
  data: ()=> ({
    info: null
  }),
  components: {
    'info-panel': infoPanel,
  },
  methods: {
    getData() {
      postApi.postDetail({
        postsId: this.$route.query.postsId
      }).then(res => {
        if (res.data) {
          this.info = res.data.postsInfo
        }
      })
    },
  },
  created() {
    console.log("home detail create")
    this.getData()
  },

  //  beforeRouteEnter (to, from, next) {
  //       console.log("home detail router enter")
  //       next(vm => {
  //           console.log(from.name)
  //            if (from.name != 'postChoose') {
  //                vm.getData()
  //           }
            
  //       })
        
  //   },
}
</script>

<style lang="less" scoped>
.detailpage {
  padding: 15px;
  padding-top: 0;
}
</style>